<template>
    <section class="table-section" v-loading="process.loading" style="height: 100%">
        <el-drawer
            :visible.sync="show_drawer"
            :with-header="false"
            size="250px"
        >
            <div class="front-table-draw-header">
                <h5>搜索参数设置</h5>
            </div>
            <slot></slot>
        </el-drawer>
        <!--工具条-->
        <div class="h-100 d-flex flex-column">
            <el-row class="toolbar" style="padding: 0;background-color: transparent;border: none;"></el-row>
            <div class="toolbar" style="padding: 0;background-color: transparent;border: none;">
                <!-- tool button -->
                <div class="d-flex">
                    <div v-if="btn_add" style="width: 44px;margin-right: 10px;">
                        <el-button size="small" type="primary" @click="handleAdd(0)"
                                   style="height:36px;width: 44px;">
                            <i class="el-icon-circle-plus"></i>
                        </el-button>
                    </div>
                    <div class="flex-grow-1">
                        <el-input size="medium" clearable v-model="search.search" @clear="handleSearch"
                                  @keyup.native="debounced()"
                                  :placeholder="('搜索')">
                            <template slot="append" @click="handleSearch"><i class="el-icon-search"></i></template>
                        </el-input>
                    </div>
                    <div v-if="btn_menu" style="width: 44px;margin-left: 10px;">
                        <el-button size="small" type="info" @click="handleShowDrawer"
                                   style="height:36px;width: 44px;">
                            <i class="el-icon-menu"></i>
                        </el-button>
                    </div>
                </div>
            </div>

            <!--列表-->
            <section style="flex-grow:1;overflow-y: auto;overflow-x: hidden; -webkit-overflow-scrolling:touch;">
                <el-table :data="records" @sort-change="sortIsChange" :show-header="false"
                          @selection-change="doSelect" @filter-change="doFilter" size="medium"
                          :border="true" v-show="header.length">
                    <!-- <el-table-column type="selection" width="55" :selectable="checkSelectable">
                    </el-table-column> -->
                    <el-table-column>
                        <template slot-scope="scope">
                            <dl v-for="(item) in filter_header" class="d-flex mb-0"
                                :key="item.key">
                                <dt class="mb-0 mr-1" style="min-width: 3em;">{{ item.title }}:</dt>
                                <dd class="mb-0" v-html="scope.row[item.key]"></dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column :label="('操作')" width="50px">
                        <template slot-scope="scope">
                            <el-button v-if='btn_review' size="small" @click="handleReview(scope.$index, scope.row)"
                                       circle class="d-block"
                                       icon="el-icon-search"></el-button>
                            <el-button class="d-block ml-0 mt-2" v-if='btn_edit' :disabled="scope.row.should_not_edit"
                                       size="small"
                                       @click="handleEdit(scope.$index, scope.row)" circle
                                       icon="el-icon-edit"></el-button>
                            <el-button class="d-block ml-0 mt-2" v-if='btn_copy' type="info" size="small"
                                       @click="handleCopy(scope.$index,scope.row)" circle
                                       icon="el-icon-copy-document"></el-button>
                            <el-button class="d-block ml-0 mt-2" v-if='btn_delete' :disabled="scope.row.should_not_edit"
                                       type="danger" size="small"
                                       @click="handleDel(scope.$index, scope.row)" circle
                                       icon="el-icon-close"></el-button>
                        </template>
                    </el-table-column>
                </el-table>

            </section>

            <!--工具条-->
            <div class="toolbar">
                <el-pagination layout="prev, pager, next"
                               :page-size="page.size"
                               :current-page="page.current"
                               :total="page.total"
                               style="float:right;"
                               @current-change="handleCurrentChange">
                </el-pagination>
                <el-checkbox v-if="past" @change="handlePast" style="margin-top: 7px" true-label="1" false-label="0"
                             v-model="search.filters.past">过期
                </el-checkbox>
            </div>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "front-mobile-table",
    data() {
        return {
            single: {},
            show_pic: false,
            is_first: true,
            selects: [],
            page: this.front_page,
            search: this.front_search,
            records: this.front_records,
            debounced: null,
            header: this.front_header,
            show_drawer: false,
        }
    },
    watch: {
        front_page: {
            handler(newVal) {
                this.page = newVal
            },
        },
        front_records: {
            handler(newVal) {
                this.records = newVal
            },
            deep: true
        },
        front_header: {
            handler(newVal) {
                this.header = newVal
            },
            deep: true
        },
    },
    props: {
        front_page: {
            type: Object,
            default() {
                return {}
            }
        },
        front_search: {
            type: Object,
            default() {
                return {}
            }
        },
        front_records: {
            type: Array,
            default() {
                return []
            }
        },
        front_header: {
            type: Array,
            default() {
                return []
            }
        },
        model: {
            type: String,
            default() {
                return '';
            }
        },
        btn_review: {
            type: Boolean,
            default() {
                return false;
            }
        },
        btn_add: {
            type: Boolean,
            default() {
                return true;
            }
        },
        btn_edit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        btn_delete: {
            type: Boolean,
            default() {
                return true;
            }
        },
        btn_copy: {
            type: Boolean,
            default() {
                return false;
            }
        },
        past: {
            type: Boolean,
            default() {
                return false;
            }
        },
        btn_menu: {
            type: Boolean,
            default() {
                return true;
            }
        }
    },
    methods: {
        checkSelectable(row) {
            return (!row.should_not_edit);
        },
        doSelect(selection) {
            this.selects = selection;
        },
        sortIsChange(column) {
            this.search.sort = {'prop': column.prop, 'order': column.order};
            this.$emit('reload', this.search);
        },
        doFilter(filters) {
            this.search.filters = Object.assign({}, this.search.filters, filters);
            this.$emit('reload', this.search);
        },
        handleEdit(index, row) {
            this.handleAdd(row.id);
        },
        handleAdd(id) {
            this.$emit('edit', id);
        },
        handleCopy(index, row) {
            this.$emit('copy', row.id);
        },
        handleReview(index, row) {
            this.$emit('review', row.id);
        },
        handleDel: function (index, row) {
            this.$confirm(('您确认删除该记录吗'), ('提示'), {
                type: 'warning',
                dangerouslyUseHTMLString: true

            }).then(() => {
                this.$api(this.model + '.Delete', {id: row.id}, true)
                    .then(() => {
                        this.$notify({
                            message: ('删除记录成功!'),
                            type: 'success'
                        });
                        this.$emit('reload', this.search);
                    });
            }).catch(() => {
            });
        },
        handleBatchDel: function () {
            let vm = this;
            this.$confirm(('您确认删除这些记录吗?'), ('信息'), {
                type: 'warning',
                dangerouslyUseHTMLString: true

            }).then(() => {
                this.$api(vm.model + '.Delete', {ids: vm.selects.map(i => i.id)}, true)
                    .then(() => {
                        this.$notify({
                            message: ('删除记录成功!'),
                            type: 'success'
                        });
                        this.$emit('reload', this.search);
                    });
            }).catch(() => {
            });
        },
        handleCurrentChange(val) {
            this.search.page = val;
            this.$emit('reload', this.search);
        },
        handleSearch() {
            this.$emit('reload', this.search);
        },
        handlePictureOpen(pic) {
            this.single = pic;
            this.show_pic = true;
        },
        handlePast() {
            if (this.search.past == 0) {
                this.search.sort = {};
            } else {
                this.search.sort = {'prop': 'start_time', 'order': 1};
            }
            this.handleSearch();
        },
        handleShowDrawer() {
            this.show_drawer = true;
        }
    },
    computed: {
        ...mapState(['process']),
        defaultSort() {
            let order = {};
            if (this.header.length > 0) {
                this.header.forEach(h => {
                    if (h.default) {
                        order = {prop: h.key, order: h.default === 'asc' ? 'ascending' : 'descending'};
                    }
                });
            }
            return order;
        },
        filter_header() {
            return this.header.filter(function (item) {
                return !item.hidden;
            })
        },
        english() {
            return false;
        },
        calculateWidth() {
            let width = (this.btn_review ? 1 : 0) + (this.btn_edit ? 1 : 0) + (this.btn_copy ? 1 : 0) + 1;
            return width * 50;
        }
    },
    mounted() {
        let vm = this;
        this.debounced = this.$debounce(1000, function () {
            vm.$emit('reload', vm.search);
        });
    },
}
</script>

<style lang="scss">

.table-section {
    //border: 1px solid #ccc !important;

    .toolbar {
        position: relative;
        font-size: 12px;
        border: 1px solid #ebeef5;

        background: #fff;
        padding: 5px 10px;
        //border:1px solid #dfe6ec;
        margin: 10px 0;

        .el-form-item {
            margin-bottom: 5px;
        }
    }

    .front-table-draw-header {
        background-color: rgb(221, 221, 221);
        padding: 15px 0;
        text-align: center;
        margin-bottom: 10px;
    }

    .el-table {
        thead {
            tr {
                background-color: #d9e5f1;

                th {
                    color: #37474f;
                    background-color: #d9e5f1;

                    .cell {
                        line-height: 34px;
                    }

                    .el-table__column-filter-trigger {
                        float: right;
                    }

                    i.el-icon-arrow-down {
                        transform: scale(1.3);
                        //margin-left: 5px;
                        transition: all 0.1s ease-in;

                        &:before {
                            content: "\e6ca";
                        }

                        &:hover {
                            transform: scale(1.8);
                            color: #3794ff;
                        }
                    }

                    .highlight {
                        i.el-icon-arrow-down {
                            color: #3794ff;
                        }
                    }
                }
            }
        }
    }
}

.large-image {
    cursor: zoom-out;

    &.enlarge {
        max-width: 100%;
        max-height: calc(80vh - 63px);
        cursor: zoom-in;
    }
}

</style>
