<template>
    <el-dialog v-mobile-dialog class="front-dialog" :title="single.id?'编辑':'增加'" :visible.sync="edit"
               :close-on-click-modal="false" :before-close="handleClose"
               width="calc(100% - 10px)" append-to-body>
        <el-form :model="single" label-width="auto" ref="editForm" :rules="rules">
            <front-single-form :fields="fields" :single="single" ></front-single-form>
            <div class="clearfix"></div>
        </el-form>
        <slot></slot>
        <div slot="footer" class="">
            <slot name="footer"></slot>
            <el-button @click.native="$emit('cancel')" size="small">{{'放弃'}}</el-button>
            <el-button type="primary" @click.native="editSubmit" :loading="loading" size="small">{{('提交')}}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {mapState} from 'vuex';
    import FrontSingleForm from "../components/front-single-form";
    import  "../common/directives/mobile-dialog";

    export default {
        name: "edit-dialog",
        components:{FrontSingleForm},
        data() {
            return {
                edit: this.edit_mode
            }
        },
        props: {
            single: {
                type: Object,
                default() {
                    return {}
                }
            },
            fields: {
                type: Object,
                default() {
                    return {}
                }
            },
            edit_mode: {
                type: [Boolean, Number],
                default() {
                    return false
                },
            },
            model: {
                type: String,
                default() {
                    return ''
                },
            },
            dual: {
                type: [Boolean, Number],
                default() {
                    return false
                },
            }
        },
        watch: {
            edit_mode: {
                handler(newVal) {
                    this.edit = newVal;
                }
            }
        },
        computed: {
            ...mapState('process', ['loading']),
            rules() {
                let rules = {};
                for (let x in this.fields) {
                    const field = this.fields[x];
                    if (field.validation) {
                        rules[x] = [];
                        field.validation.forEach(item => {
                            const trigger = ['text', 'textarea', 'number'].includes(item.type) ? 'blur' : 'change';
                            if (item === 'required') {
                                rules[x].push({
                                    required: true,
                                    message: this.english ? ((field.title_en || field.title) + " is required!") : ('请输入' + field.title),
                                    trigger: trigger
                                });
                            }
                            if (item.indexOf('min:') === 0 || item.indexOf('max:') === 0) {
                                let cmd = item.split(':');
                                if (cmd.length > 1) {
                                    let v = {
                                        message: (cmd[0] === 'min' ? ('最小') : ('最大')) + cmd[1] + ('个字'),
                                        trigger: 'blur'
                                    };
                                    v[cmd[0]] = parseInt(cmd[1]);
                                    rules[x].push(v);
                                }
                            }
                            if (item.indexOf('email') === 0) {
                                rules[x].push({
                                    type: 'email',
                                    message: ('请输入正确的邮箱格式'),
                                    trigger: trigger
                                });
                            }
                        });
                    }
                }
                return rules;
            },
            english() {
                return false;
            }
        },
        methods: {
            editSubmit() {
                let vm = this;
                if (this.model) {
                    this.$refs['editForm'].validate(valid => {
                        if (valid) {
                            this.$api(this.model + '.Edit', this.single, true).then(() => {
                                    vm.edit = false;
                                    this.$notice.success('记录更新成功!');
                                    vm.$emit('success');
                                }
                            );
                        } else {
                            this.$notice.error('请按照要求填写数据!');
                        }
                    });
                }
            },
            handleClose(done) {
                done();
                this.$emit('cancel');
            },
            makeFetchSuggestions(item) {
                console.log('item:',item);
                return function (a, cb) {
                    cb(item.options.map((i) => {
                        return {value: i, label: i};
                    }));
                }
            }
        }
    }
</script>

<style lang="scss">
    .front-dialog {
        .el-dialog__footer {
            border-top: 1px solid #bfcbd8;
            padding-top: 20px;
        }

        .el-dialog__header {
            border-bottom: 1px solid #bfcbd8;
            padding-bottom: 20px;
        }
        .el-input-number .el-input__inner {
            text-align: left;
        }
    }
</style>
